import Logo from "../../../images/everflex_logo.svg";

export default function ApplicationLogo({ app_logo }: { app_logo?: string }) {
    function getLogo() {
        return app_logo !== null && app_logo !== undefined ? app_logo : Logo;
    }

    return (
        <img src={getLogo()} className="application-logo" alt="everflex logo" />
    );
}
